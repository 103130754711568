import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"

import News from "../../../components/News"
import CaseStudeiesTopSection from "../../../components/CaseStudeiesTopSection"
import CaseStudeiesSimplified from "../../../components/CaseStudeiesSimplified"
import CaseStudyDescription from "../../../components/caseStudyDescription"

import headerImage from "../../../images/caseStudies/file-1556991421 1.png"
import IconsAdded from "../../../components/IconsAdded"

const ORR = ({ data }) => {
  return (
    <div className="CaseStudeies">
      <Layout pageTitle={"Vsiomate - Resource augmentation for ORR Protection"}
      descrip = {"Need flexible staffing solutions? Staff augmentation provides access to skilled professionals, boosts productivity, and reduces costs. Get started now."}>
        <CaseStudeiesTopSection
          path='projects'
          headerData={{
            title:
              "Resources Augmentation: We provided ORR mutiple resources to work as part of there team.",
            peragraph:
              "Throughout our relationship, our team adhered to the Scrum development approach and kept strong cooperation with the ORR team. This long-lasting collaboration, which lasted more than two years, demonstrated our dedication to providing ORR with high-caliber solutions and promoting its long-term success.",
            image: headerImage,
          }}
          data={data}
        />
        <CaseStudyDescription
          caseStudyData={{
            ITitle: "Introduction",
            Idescription:
              "Since 1948, ORR Corporation has been a leader in providing tailored fire protection solutions for mission-critical business processes. Renowned for our expertise and dedication, they serve some of the largest and most esteemed companies globally. Their comprehensive approach ensures the safety of personnel, assets, and the environment, earning us trust and recognition across industries.",
            image: headerImage,
            data: [
              {
                Title: "keyReqProblem",
                Point1:
                  "Participate in development of Technician Planner & Portal and Net Reporting",
                Point2:
                  "Participate in development of multiple enhancement on Odoo",
                Point3: "Participate in development of integration with Odoo",
              },
              {
                Title: "Solution",
                Sdescription:
                  "Visiomate provide multiple resources to ORR IT team, we worked are an extented team. We were involved in all their major projects, providing them quality development services. ",
                Point1:
                  "Development: Backend and fronted teams are assisting ORR team dedicatedly in development of a new portals and components.",
                Point2:
                  "Upgradation: Upgrading old functions, giving them a new and advanced look along with the addition of new features in old and newly developed components.",
                Point3:
                  "Continuous Technical Support: Ongoing technical support on for their ERP (Odoo) customization & new features.",
              },
            ],
          }}
          data={data}
        />
        <CaseStudeiesSimplified
          TextData={{
            title: "Approach that Achieved Success",
            Data: [
              {
                peragraph: "Demo and Evaluation",
                list: [
                  "Schedule product demonstrations. ",
                  "Evaluate the software's usability, features, and performance.",
                  "Engage key stakeholders in the evaluation process.",
                ],
              },
              {
                peragraph: "Scalability and Integration",
                list: [
                  "Identify the potential integrations required by the customer.",
                  "Plan the implementation of identified integrations.",
                ],
              },
              {
                peragraph: "User Training",
                list: [
                  "User training is a critical component of successfully implementing the software solution. ",
                  "Proper training ensures that users can effectively and efficiently utilize the software, maximizing its benefits.",
                ],
              },
              {
                peragraph: "Post-Implementation Support",
                list: [
                  "Provide ongoing support for users.",
                  "Address any post-implementation issues.",
                  "Evaluate and implement system enhancements based on feedback.",
                ],
              },

            ],
          }}
        />
        <News isAbout={true} career={true} />
      </Layout>
    </div>
  )
}

export default ORR
export const query = graphql`
  query CSorrImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsHeaderImg: file(
      relativePath: { eq: "caseStudies/file-1556991421 1.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondImg: file(
      relativePath: { eq: "caseStudies/file-1556991421 1.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
